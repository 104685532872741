import React, { useState, useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import styled from '@emotion/styled';
import 'react-day-picker/dist/style.css';
import { StyledHeader } from './styles';
import { UISubmitButton } from '../../shared/ui/Button';
import { UIBackButton } from '../../shared/ui/Back';
import * as moment from 'moment';
import CoachModal from './CoachModal';
import { useBookSessionData } from '../../contexts/BookSessionDataProvider';
import ProfessionalInfo from './components/ProfessionalInfo';
import { useAppData } from '../../contexts/AppDataProvider';

const InfoContainer = styled.div`
  background-color: #f3fbfb;
  padding: 24px;
  display: flex;
  margin-top: 40px;
  flex-direction: column;
`;

const StyledForm = styled.form`
  margin-top: 32px;
  label[for='contact_number'] {
    margin-top: 20px;
  }
  textarea,
  input {
    font-size: 14px;
  }
  textarea {
    clear: both;
  }
`;
export const StyledUISubmitButton = styled(UISubmitButton)`
  width: fit-content;
  display: block;
  text-align: center;
  margin: auto;
  padding: 12px 51px;
`;

const StyledTelInput = styled.input`
  &:invalid {
    box-shadow: 0 0 4px red;
  }

  &::placeholder {
    color: lightgray;
  }
`;

const StyledNotice = styled.div`
  display: flex;
  flex-direction: row;
  p {
    margin: 0;
    margin-left: 8px;
    font-size: 12px;
    color: #c86000;
  }
`;
const TimeContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const StyledOptional = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textBodyLight};
  float: right;
`;
const PhoneNumberContainer = styled.div`
  display: flex;
  align-items: center;
  .prefix {
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.textBodyLight};
    padding: 12px;
    border: ${({ theme }) => `1px solid ${theme.colors.borderEmphasis}`};
    background: ${({ theme }) => theme.colors.surfaceContrast};
    border-radius: ${({ theme }) => theme.radius.default};
    display: inline-block;
    padding-top: 18px;
  }
  .number {
    height: 51px;
    margin-right: 8px;
    color: ${({ theme }) => theme.colors.textBodyLight};
    padding: 12px;
    border: ${({ theme }) => `1px solid ${theme.colors.borderEmphasis}`};
    border-radius: ${({ theme }) => theme.radius.default};
    display: inline-block;
    color: #212529;
    padding-top: 10px;
  }
  margin-bottom: 8px;
`;
const LightText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textBodyLight};
  margin-bottom: 0px;
`;
const ReviewContainer = styled.div`
  max-width: 662px;
  margin: auto;
`;
const ReviewSession = () => {
  const {
    setCurrentStep,
    selectedReasons,
    selectedSession,
    selectedDateSlotType: { slot, date },
    reviewDetails: userForm,
    setReviewDetails: setUserForm,
    selectedMeetingSetup,
  } = useBookSessionData();
  const { phone } = useAppData();
  const [formError, setFormError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [professional, setProfessional] = useState({});
  const slotTime = moment(new Date(slot.start_time));
  const [showCoachModal, setShowCoachModal] = useState(false);

  const DisplayHeaderText = () => {
    const session = selectedSession == 'coaching' ? 'COACHING' : 'COUNSELING';
    const meetingSetup =
      selectedMeetingSetup == 'on_site' ? 'IN-PERSON' : 'VIRTUAL';
    return (
      <StyledHeader className="display-header text-left m-0">{`${meetingSetup} ${session}`}</StyledHeader>
    );
  };

  const selectedDate = () => {
    const slot_date = slot.start_time.split('T')[0];
    const date_parts = slot_date.split('-');
    return `${date_parts[2]}/${date_parts[1]}/${date_parts[0]}`;
  };

  const postMeeting = async () => {
    const selectedReasonIDs = selectedReasons.map((object) => object.id);
    const payload = {
      professional_id: slot.professional,
      time_slot: slot.start_time,
      date: selectedDate(),
      additional_notes: userForm.notes,
      phone: userForm.phone,
      meeting_setup: selectedMeetingSetup,
      coaching_reason_ids: selectedReasonIDs,
      purpose: selectedSession,
      consent_signed: true,
      on_site_location: slot.on_site_location
    };

    const res = await fetch('/api/v2/meetings', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    let responseBody = await res.json();

    if (res.status == 200) {
      setUserForm({
        ...userForm,
        ...{ booking_details: responseBody, professional: professional },
      });
      setCurrentStep('booking_done');
    } else {
      let form_error =
        responseBody?.errors[0]?.phone?.join('') ||
        responseBody?.errors[0]?.start_time?.join('') ||
        'Your booking could not be completed at this time. Please check the details you provided. If the issue persists, contact our support team at support@infinitcare.co or reach out to us via Chat.';

      if (
        !!responseBody?.errors &&
        (responseBody?.errors[0]?.base || []).includes('Invalid schedule')
      ) {
        form_error =
          'There was an issue with the time slot that you are trying to book. Please try a different time slot. If the issue persists, contact our support team at support@infinitcare.co or reach out to us via Chat.';
      }
      setFormError(form_error);
      setSubmitted(false);
    }
  };

  const fetchProfessional = async () => {
    const res = await fetch(`/api/v2/professionals/${slot.professional}`);
    if (res.status == 200) {
      setProfessional(await res.json());
    }
  };

  useEffect(() => {
    fetch('/api/v2/track/track_client_page_view', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        page: 'Session Details',
        purpose: selectedSession,
      }),
    });

    fetchProfessional();
  }, []);

  useEffect(() => {
    setUserForm({ ...userForm, ...{ phone: phone?.replace('+63', '') } })
  }, [phone]);

  const submitBooking = (event) => {
    event.preventDefault();
    setSubmitted(true);
    postMeeting();
  };

  return (
    <>
      <CoachModal
        showCoachModal={showCoachModal}
        setShowCoachModal={setShowCoachModal}
        professional={professional}
      />
      <UIBackButton onClick={() => setCurrentStep('select_date_time')} />
      <ReviewContainer>
        <StyledHeader>
          <p className="book-session">BOOK A SESSION</p>
          <h3>Almost there! Ready to submit?</h3>
        </StyledHeader>
        <Row className="justify-content-center h-100 m-0 d-block">
          <Col xl={12}>
            <InfoContainer>
              <DisplayHeaderText />
              <TimeContainer>
                <span className="d-block">
                  {moment(date).format('dddd, LL')}
                </span>
                <span className="d-block">
                  {slotTime.format('LT')} to{' '}
                  {slotTime.add(1, 'hours').format('LT')}
                </span>
              </TimeContainer>
              <StyledNotice>
                <Image src="error-exclamation-mark.svg" />{' '}
                <p>
                  Please check that you are free from other commitments during
                  this time.
                </p>
              </StyledNotice>
            </InfoContainer>
          </Col>
          {professional && (
            <Col xl={12}>
              <ProfessionalInfo
                professional={professional}
                onClick={() => setShowCoachModal(true)}
              />
            </Col>
          )}
          <StyledForm id="confirm-booking-form" onSubmit={submitBooking}>
            <Col xl={12} className='mb-4'>
              <label htmlFor="notes">
                Please share anything that will help yours{' '}
                {selectedSession == 'coaching' ? 'coach' : 'counselor'} prepare
                for your session.
              </label>
              <StyledOptional>(Optional)</StyledOptional>
              <textarea
                name="notes"
                className="form-control"
                placeholder="Your message here…"
                rows="4"
                value={userForm?.notes || ''}
                onChange={(event) =>
                  setUserForm({ ...userForm, ...{ notes: event.target.value } })
                }
              />
            </Col>
            <Col xl={12}>
              <LightText style={{ fontSize: '14px' }}>Contact Number</LightText>
              <PhoneNumberContainer>
                <span className="prefix">+63</span>
                <StyledTelInput
                  type="tel"
                  onChange={(event) =>
                    setUserForm({ ...userForm, ...{ phone: event.target.value } })
                  }
                  value={userForm?.phone}
                  pattern="^(9\d{9})$"
                  placeholder='9170123456'
                  className="form-control number"
                  required
                />
              </PhoneNumberContainer>
               {!!formError && (
                <small className="text-danger d-block">{formError}</small>
              )}
              <LightText>
                *An SMS reminder will be sent to you an hour before your
                appointment.
              </LightText>
            </Col>
            <Col xl={12} className="text-center">
              <UISubmitButton
                form="confirm-booking-form"
                className={`mt-4 text-center fit-content`}
                type="submit"
                text={'Confirm Booking'}
                disabled={submitted}
              />
            </Col>
          </StyledForm>
        </Row>
      </ReviewContainer>
    </>
  );
};
export default ReviewSession;
